import React, { useEffect } from 'react'
import { useState } from 'react'
import "../../Verfiy.css"
import homeImage from "../Verification/verfiyImage/verification.png"
import loader from "../Verification/verfiyImage/khiladi_adda_loader.gif"
import OtpInput from 'react-otp-input';
import axiosClient from '../../axios';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import CryptoJS from 'crypto-js';
import { ReactComponent as Verified } from "../Verification/verfiyImage/verified.svg"
import { ReactComponent as Wrong } from "../Verification/verfiyImage/wrong.svg"
import { ReactComponent as Pending } from "../Verification/verfiyImage/pending.svg"
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Transfer() {
    const [open, setOpen] = useState(false);
    const [opentwo, setOpentwo] = useState(false);
    const [openThree, setOpenThree] = useState(false);
    const [otp, setOtp] = useState('');
    const [minutes, setMinutes] = useState(5);
    const [seconds, setSeconds] = useState(0);
    const [canceseconds, setCanceseconds] = useState(6);
    const [otpBox, setOtpBox] = useState(false);
    const [canBox, setCanBox] = useState(true);
    const [loading, setLoading] = useState(false)
    const mobileNumber = JSON.parse(localStorage.getItem("MOBILE"));
    const localuserDatas = JSON.parse(localStorage.getItem("data"));
    const [loadMesg, setLoadMesg] = useState(false)
    const transferDetail = JSON.parse(localStorage.getItem("transferDetail"));
    const [userData, setUserData] = useState(localuserDatas)
    const jwt = JSON.parse(localStorage.getItem("JWT"));
    const [transferSec, setTransferSec] = useState(transferDetail)
    const [tDetails, setTdetails] = useState(true)
    const [dialog, setDialog] = useState({
        icon: 0,
        message: "Under Process"
    })
    const handleDelete = () => {
        setOpen(false)
    }
    const getRandomDelay = () => {
        return Math.floor(Math.random() * (5000 - 2000 + 1)) + 2000;
    };
    const handleOpen = (i, m) => {
        setOpen(true);
        setDialog({
            icon: i,
            message: m
        })
    }
    const navigate = useNavigate()
    const handleDeletetwo = () => {
        localStorage.clear();
        navigate("/login")
    }


    // user login otp
    const sendOtp = async () => {
        setOtpBox(true)
        const data = {
            mobile: mobileNumber
        }
        setLoading(true);
        await axiosClient
            .post(`/auth/login`, data)
            .then(async (res) => {
                setLoading(false);
                if (res.data.status === false) {
                    handleOpen(0, res.data.message)
                }
                else if (res.data.status === true) {
                    setOtpBox(true);
                    handleOpen(1, "Enter 6 Digit OTP send on your Mobile Number.")
                    const randomDelay = getRandomDelay();
                    console.log("randomDelay", randomDelay)
                    await new Promise((resolve) => setTimeout(resolve, randomDelay));

                    await axiosClient
                        .put('/user/website/wallet/amount', {}, { // snapshot
                            headers: {
                                'token': jwt
                            }
                        }).then((re) => console.log(re)).catch((err) => console.log("e", err))
                }
            })
            .catch((err) => console.log(err))
    }
    const endFunction = () => {
        setOpentwo(true)
        setDialog({
            icon: 1,
            message: "Coins transfered Successfully.\n Please visit your KhiladiAdda app and go to My Wallet section and check your updated wallet balance there."
        })
    }
    // user login otp submission
    const submitOtp = async () => {
        if (otp?.length < 6) {
            return
        }
        if (otp?.length === 6) {
            const encryptedKey = process.env.REACT_APP_TRANSFER_KEY
            const stringToBeHash = `${transferDetail.transfer}|${otp}|${userData.user_id}||||${encryptedKey}`;
            const base64Data = btoa(stringToBeHash);
            let hash = CryptoJS.SHA256(base64Data).toString();
            const data = {
                transferAmount: Number(transferDetail?.transfer),
                otp: otp,
                hash: hash
            }
            setLoading(true);
            setLoadMesg(true)
            const randomDelay = getRandomDelay();
            await new Promise((resolve) => setTimeout(resolve, randomDelay));
            await axiosClient
                .put(`/transfer/website/wallet-amount`, data, { // transfer coins
                    headers: {
                        'token': jwt
                    }
                })
                .then(async (res) => {
                    setLoading(false);
                    setLoadMesg(false)
                    if (res?.data?.status === true) {
                        setOtpBox(false)

                        try {
                            await axiosClient.get("/profile?transaction=false", {
                                headers: {
                                    'x-access-token': jwt,
                                    'token': jwt
                                }
                            }).then((respon) => {
                                if (respon.data.status == true) {
                                    let coins_convert = respon.data.response.coins_converted
                                    let todayDate = new Date()
                                    let initialDate = moment.utc(coins_convert?.day).local().format('DD') || 0
                                    // let bonus2 = (respon.data.response.coins_converted.n_times == 0 || Number(initialDate) != Number(todayDate.getDate())) ? 5 : (respon.data.response.coins_converted.n_times == 1) ? 3 : 2;

                                    // if (respon.data.response.w_d_trans_config && respon.data.response.w_d_trans_config.n_transfer_allowed > 0) {
                                    //     const totalCoinsConverted = respon.data.response.coins_converted.n_times;
                                    //     if (respon.data.response.w_d_trans_config.percentage[totalCoinsConverted]) {
                                    //         bonus2 = respon.data.response.w_d_trans_config.percentage[totalCoinsConverted];
                                    //     }
                                    // }
                                    let bonus2 = respon.data.convertPercentage
                                    handleOpen(1, `Amount transfer Successfully.`)
                                    setTdetails(false);
                                    let datas = respon.data.response
                                    let saves = {
                                        user_id: datas._id,
                                        name: datas.name,
                                        dob: datas.dob,
                                        city: datas.city,
                                        state: datas.state,
                                        country: datas.country,
                                        zip_code: datas.zip_code,
                                        mobile: datas.mobile,
                                        info: datas.coins,
                                        winnig: respon.data?.winningAmount,
                                        coins_converted: bonus2
                                    }
                                    setUserData(saves)
                                    localStorage.setItem("data", JSON.stringify(saves))
                                }
                            })
                        } catch (error) {
                            console.log("error", error)
                        }
                    }
                    else {
                        handleOpen(0, res?.data.message)
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    setLoadMesg(false)

                    handleOpen(0, "Otp Verification Failed.Please try again later")
                });
        }

    }

    const resendOtp = async () => {
        setMinutes(5);
        setSeconds(0);
        setLoading(true);
        const data = {
            mobile: mobileNumber
        }
        await axiosClient
            .post(`/auth/login`, data)
            .then(async (res) => {
                setLoading(false);
                if (res.data.status === false) {
                    handleOpen(0, res.data.message)
                }
                else if (res.data.status === true) {
                    setOtpBox(true);
                    handleOpen(1, res.data.message)
                    const randomDelay = getRandomDelay();
                    await new Promise((resolve) => setTimeout(resolve, randomDelay));
                    await axiosClient
                        .put('user/website/wallet/amount', {}, {
                            headers: {
                                'token': jwt
                            }
                        }).then((re) => console.log(re)).catch((err) => console.log("e", err))
                }
            })
            .catch((err) => console.log(err))
    }
    useEffect(() => {
        if (otpBox) {
            const interval = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                }

                if (seconds === 0) {
                    if (minutes === 0) {
                        clearInterval(interval);
                    } else {
                        setSeconds(59);
                        setMinutes(minutes - 1);
                    }
                }
            }, 1000);

            return () => {
                clearInterval(interval);
            };
        }

    }, [otpBox, seconds]);
    useEffect(() => {

        const interval = setInterval(() => {
            if (canceseconds > 0) {
                setCanceseconds(canceseconds - 1);
            }
            if (canceseconds === 0) {
                clearInterval(interval);
                setCanBox(false)
            }
        }, 1000);

        return () => {

            clearInterval(interval);
        };

    }, [canceseconds]);
    useEffect(() => {
        window.scrollTo(0, 0);

        window.location.hash = "no-back-button";
        window.location.hash = "Again-No-back-button";
        window.onhashchange = function () {
            window.location.hash = "no-back-button";
        }
        if (openThree == false) {
            setOpenThree(true)
        }

    }, [])
    return (
        <>
            <div className='verfiyMain'>
                <div className='verfiyTop'>
                    <h1>Complete Coins Transfer</h1>
                </div>
                {loading ?
                    <div className='loaderKaBox'>
                        <img src={loader} alt='loader' className='loaderKA' />
                        {loadMesg ? <p>We are processing your transfer. It may take few seconds. Please don't press back button.</p> : ""}
                    </div>
                    : <>

                        <div className='verifyBody'>
                            <div className='homeUImage'>
                                <img src={homeImage} alt="homeImage" />
                            </div>



                            {
                                otpBox ?
                                    <>
                                        <div className='inputDetails'>
                                            <p className='para'>Enter the 6 digit OTP</p>
                                            <OtpInput
                                                value={otp}
                                                onChange={setOtp}
                                                numInputs={6}
                                                inputType="number"
                                                inputStyle="otpInput"
                                                renderSeparator={<span>-</span>}
                                                renderInput={(props) => <input {...props}

                                                />}
                                            />
                                        </div>
                                        <div className="countdown-text">
                                            {seconds > 0 || minutes > 0 ? (
                                                <p>
                                                    Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                                                    {seconds < 10 ? `0${seconds}` : seconds}
                                                </p>
                                            ) : (
                                                <p>Didn't recieve code?</p>
                                            )}
                                            <div

                                                onClick={() => {
                                                    if (seconds > 0 || minutes > 0) {
                                                        return
                                                    } else {
                                                        resendOtp();
                                                    }
                                                }}
                                                className={seconds > 0 || minutes > 0 ? 'resendButton' : 'resendButton active'}
                                            >  <p>
                                                    Resend OTP
                                                </p>
                                            </div>
                                        </div>
                                        <div className='verfiysubmitCover'>
                                            <h3 className='verifysubmit' onClick={() => submitOtp()}>Submit</h3>
                                        </div>
                                    </> : tDetails ?
                                        <div className='aadhaarDetails'>
                                            <h3>Transfer Details</h3>
                                            <div className='basicDetail'>
                                                <p className='detailBox'><span>Winning Wallet : </span> {Number(transferSec?.winWallet).toFixed(2)}</p>
                                                <p className='detailBox'><span>Transfer Amount : </span> {Number(transferSec?.transfer).toFixed(2)}</p>
                                                <p className='detailBox'><span>Cashback amount ({transferSec?.handle == 1 ? "Deposit" : "Bonus"})  : </span> {Number(transferSec?.cbAmount).toFixed(2)}</p>
                                                <p className='detailBox'><span>Final Pay   : </span> {Number(transferSec?.finalPay).toFixed(2)}</p>
                                                <p className='detailBox'><span>Remaining amount in winnings  : </span> {Number(transferSec?.remain).toFixed(2)}</p>
                                            </div>
                                            <div className='verfiysubmitCover flexWrap'>
                                                <p className='verifysubmit' onClick={() => { if (!canBox) { return navigate("/login") } else { return alert("Please wait 5 Seconds.") } }}>Cancel</p>
                                                <p className='verifysubmit' onClick={() => sendOtp()}>Transfer Now</p>
                                            </div>
                                        </div> :
                                        <div className='aadhaarDetails'>
                                            <h3>User Details</h3>
                                            <div className='basicDetail'>
                                                <p className='detailBox'><span>Name : </span> {userData?.name}</p>
                                                <p className='detailBox'><span>Mobile Number : </span> {userData?.mobile}</p>
                                                <p className='changes'>Updated Balances of your wallet is given below : </p>
                                                <p className='detailBox'><span>Deposit Balance : </span> {Number(userData?.info?.deposit).toFixed(2)}</p>
                                                <p className='detailBox'><span>Winning Balance : </span> {Number(userData?.info?.winning).toFixed(2)}</p>
                                                <p className='detailBox'><span>Bonus Balance: </span> {Number(userData?.info?.bonus).toFixed(2)}</p>
                                            </div>
                                            <div className='verfiysubmitCover flexWrap'>
                                                <p className='verifysubmit' onClick={() => { endFunction() }}>Okay</p>
                                            </div>
                                        </div>
                            }
                        </div>
                    </>}
            </div >

            <Dialog
                open={open}
                onClose={() => handleDelete()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className='dialogBox'>
                    <div className='dialogCover'>
                        <div className='iconsImage'>
                            {
                                dialog?.icon === 0 ? <Wrong /> : dialog?.icon === 1 ? <Verified /> : <Pending />
                            }
                        </div>
                        <div className='messageBox'>
                            <p>{dialog?.message}</p>
                        </div>
                        <div className='okay' onClick={() => handleDelete()}>Okay</div>
                    </div>
                </div>
            </Dialog >


            <Dialog
                open={opentwo}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className='dialogBox'>
                    <div className='dialogCover'>
                        <div className='iconsImage'>
                            {
                                dialog?.icon === 0 ? <Wrong /> : dialog?.icon === 1 ? <Verified /> : <Pending />
                            }
                        </div>
                        <div className='messageBox'>
                            <p>{dialog?.message}</p>
                        </div>
                        <div className='okay' onClick={() => handleDeletetwo()}>Okay</div>
                    </div>
                </div>
            </Dialog >
            <Dialog
                open={openThree}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className='dialogBox'>
                    <div className='dialogCover'>
                        <div className='iconsImage'>
                            <Pending />
                        </div>
                        <div className='messageBox'>
                            <p>If you transfer amount from Winning to Deposit, then your 1 daily withdrawal limit will be reduced.</p>
                        </div>
                        <div className='okay' onClick={() => setOpenThree(false)}>Okay</div>
                    </div>
                </div>
            </Dialog >
        </>
    )
}

export default Transfer